//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SectionTemplate from './SectionTemplate.vue'
export default {
  extends: SectionTemplate,
  data(){
      return {
          showModal: false
      };
  },
  methods: {
      openModal: function () {
        var m = this;
        setTimeout(function () {
          m.showModal  = true;
        }, 1000);
      },
      closeModal: function () {
        var x = this;
        x.showModal  = false;
      },
    },
    mounted() {
      this.openModal();
    }
}

